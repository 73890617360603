<template>
  <footer
    class="fixed bottom-0 w-full py-5 bg-white border flex justify-between p-4 px-0 max-w-2xl mx-auto shadow-lg"
  >
    <div class="w-full flex items-center justify-around flex-row mx-auto ">
      <div>
        <button class="space-x-2" @click="goTo('wishes')">
          <span><i class="fa-regular fa-square-heart"></i></span>
          <span>Wishes</span>
        </button>
      </div>

      <div>
        <button class="space-x-2" @click="goTo('favorite')">
          <span><i class="fa-regular fa-star"></i></span>
          <span>Favorite & Sort</span>
        </button>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">

const goTo = (page: string) => {

  switch (page) {
    case 'wishes':
      useRouter().push('/v2/brand/wishes')
      break;

    case 'favorite':
      useRouter().push('/v2/brand/favorite')
      break;
  
    default:
      break;
  }

}

</script>

<style scoped>

</style>