<template>
  <header
    class="fixed inset-x-0 top-0 z-40 flex h-16 border border-gray-900/10 bg-white shadow-sm mx-auto"
  >
    <div
      class="mx-auto flex w-full max-w-7xl items-center justify-between px-2 sm:px-6 lg:px-8"
    >
      <div class="w-full flex justify-between md:justify-start  items-center">
        <div class="w-full md:w-fit h-16 flex justify-start items-center">
          <p class="w-full px-2 text-lg font-medium">{{eventTitle}}</p> 
        </div>

        <div class="hidden md:flex md:w-fit md:ml-10  justify-end md:justify-start items-center space-x-2">
          <button 
            @click="goTo('wishes')"
            class="w-20 h-16 bg-transparent" 
            :class="{'border-b-4 border-gray-700 mt-1' : currentPage === 'wishes'}"
          >Wishes</button>

          <button 
            @click="goTo('favorite')"
            class="w-44 h-16  bg-transparent" 
            :class="{'border-b-4 border-gray-700 mt-1' : currentPage === 'favorite'}"
          >Favorite And Sort</button>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
const route = useRoute();
const router = useRouter();
const appStateStore = useAppStateStore();
const authStore = useAuthStore();
const eventStore = useEventStore();

const eventTitle = computed(() => {
  return eventStore?.event?.information?.title || 'Photowish'
});
const title = computed(() => appStateStore.menuSelected);

const profile = computed(() => authStore.user && authStore.user.line);
const currentPage = computed(() => {
  const path = route.fullPath

  switch (path) {
    case '/v2/brand/wishes':
      return 'wishes'
  
    default:
      return 'favorite'
  }
})

const goTo = (page) => {

  switch (page) {
    case 'wishes':
      router.push('/v2/brand/wishes')
      break;

    case 'favorite':
      router.push('/v2/brand/favorite')
      break;
  
    default:
      break;
  }

}

const back = () => {
  appStateStore.setMenuSelected("Photo Wish");
};

const logout = ()=>{
  // const isConfirm = confirm("ต้องการออกจากระบบหรือไม่ ?")
  // if(!isConfirm){
  //   return
  // }

  // localStorage.removeItem("brand-token-id")
  // router.push("/wish/desktop/login")

}
</script>
