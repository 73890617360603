<template>
  <div class="h-screen bg-slate-100">
    <LayoutBrandTopbar @toggle-menu="()=>{}" v-if="isShowNav"/>
    <div class=" bg-slate-100">       
        <slot></slot>
    </div>
    
    <LayoutBrandBottom class="block md:hidden" v-if="isShowNav"/>


    <GlobalLoading />
    <GlobalToast />
  </div>
</template>

<script setup lang="ts">
import { usePreweddingStore } from "@/stores/prewedding";
import { useAppStateStore } from "@/stores/appState";
import { useEventStore } from "@/stores/event";
import { useUserStore } from "@/stores/user";
import eventDatabase from "~/services/database/event";
import dayjs from "dayjs"

const route = useRoute();
const router = useRouter();
const bootstrapStore = useBootstrapStore();
const authStore = useAuthStore();
const appStateStore = useAppStateStore();
const userStore = useUserStore();
const eventStore = useEventStore();

const isLoading = ref(false)
const isShowNav = ref(false)

const tokenId = computed(() => localStorage.getItem("brand-token-id"))

onBeforeMount(async () =>{
  init()
})

const init = () => {
  setTimeout(async () => {
    appStateStore.isDesktop = true
    const token = localStorage.getItem("brand-token-id")
    console.log(token)

    if(!token) {
      router.push('/brand')
      return
    }
    
    isLoading.value = true
    const eventId = extractCodeId(token)
    const isEventAlready = await eventStore.checkEventAlready(eventId)

    if(!isEventAlready) {
      window.localStorage.removeItem('brand-token-id')
      router.push('/brand')
      return 
    } 

    // Run Bootstrap Main Desktop
    await bootstrapStore.mainEventBrand(eventId)
    isShowNav.value = true
    isLoading.value = false 
  }, 2000);
}



function extractCodeId(token: string) {
  try {
    const decodedString = atob(token); // base64 decode the token
    const payload = JSON.parse(decodedString);
    return payload.code_id || null; // Return code_id if present
  } catch (error) {
    console.error("Invalid token format", error);
    return null;
  }
}

</script>

<style scoped>

</style>